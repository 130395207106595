import EditableTable from "../Table/EditableTable";
import React from "react";
import * as TermOfUseState from "../../store/TermOfUseReducer/TermOfUseReducer";
import {TermOfUseModel} from "../../store/TermOfUseReducer/TermOfUseReducer";
import {ApplicationState} from "../../store";
import {connect} from 'react-redux';
import SnackBar from "../SnackBar/SnackBar";
import CustomSnackBar from "../SnackBar/SnackBar";

type TermOfUseProps = 
    TermOfUseState.TermOfUseState
    & typeof TermOfUseState.actionCreators

class TermOfUse extends React.Component<TermOfUseProps> {

    constructor(props) {
        super(props);
    }

    headers = [
        {value: "Идентификатор", field: "identificator"},
        {value: "Правила использования", field: "term"},
        ];
    
    componentDidMount() {
        this.props.get();
    }

    onEdit = (info: TermOfUseModel) => {
        this.props.edit(info);
    };

    onAdd = (info: TermOfUseModel) => {
        this.props.add(info);
    };

    odDelete = (id: number) => {
        this.props.delete(id);
    };
    
    render() {
        return <div>
            <h1>Правила использования</h1>
            <EditableTable
                onEdit={this.onEdit}
                onDelete={this.odDelete}
                onAdd={this.onAdd}
                headers={this.headers}
                data={this.props.termOfUses}
            />
            {this.props.error &&
            <CustomSnackBar
                message={this.props.error}
                severity={'error'}
            />}
        </div>;
    }
}

export default connect(
    (state: ApplicationState) => state.termOfUse,
    TermOfUseState.actionCreators
)(TermOfUse) as typeof TermOfUse
