
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
import {AppThunkAction} from "../index";
import {baseService} from "../../services/base.service";
import {Action, Reducer} from "redux";
import {TermOfUseService} from "../../services/TermOfUse/termOfUse.service";

export interface TermOfUseState {
    termOfUses?: TermOfUseModel[];
    isLoading?: boolean;
    error?: any;
}

export interface TermOfUseModel {
    id: number;
    identificator: string;
    term: string;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface RequestTermOfUseAction { type: "REQUEST_TERMOFUSE"}
interface RecievedTermOfUseAction { type: "RECIEVED_TERMOFUSE", termOfUses: TermOfUseModel[]}
interface FailureTermOfUseAction { type: "RECIEVED_FAILURE", error: any}
interface EditTermOfUseAction {type: 'EDIT_TERMOFUSE'}
interface AddTermOfUseAction {type: 'ADD_TERMOFUSE'}
interface DeleteTermOfUseAction {type: 'DELETE_TERMOFUSE'}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestTermOfUseAction | 
    RecievedTermOfUseAction | 
    FailureTermOfUseAction | 
    EditTermOfUseAction |
    AddTermOfUseAction |
    DeleteTermOfUseAction


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
let _termService = new TermOfUseService('api/TermOfUse');

export const actionCreators = {
  get: (): AppThunkAction<KnownAction> => ((dispatch, getState) => {
      dispatch({type: "REQUEST_TERMOFUSE"});
      _termService.get()
          .then(
              response => {
                    dispatch({type: "RECIEVED_TERMOFUSE", termOfUses: response });
              },
              error => {
                    dispatch({ type: "RECIEVED_FAILURE", error });
              });
  }),
  edit: (model: TermOfUseModel) : AppThunkAction<KnownAction> => (dispatch, getState) => {
      _termService.edit(model)
          .then(
              response => {
                dispatch({type: "EDIT_TERMOFUSE"});
                _termService.get()
                    .then(
                        response => {
                            dispatch({type: "RECIEVED_TERMOFUSE", termOfUses: response});
                        },
                        error => {
                            dispatch({ type: "RECIEVED_FAILURE", error });
                        })
              },
              error => {
                  dispatch({ type: "RECIEVED_FAILURE", error });
              } 
          );
  },
  add: (model: TermOfUseModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
      _termService.add(model)
          .then(
              response => {
                  dispatch({ type:"ADD_TERMOFUSE",});
                  _termService.get()
                      .then(
                          response => {
                              dispatch({ type: "RECIEVED_TERMOFUSE", termOfUses: response });
                          },
                          error => {
                              dispatch({ type: "RECIEVED_FAILURE", error });
                          }
                      );
              },
              error => {
                  dispatch({ type: "RECIEVED_FAILURE", error });
              }
          )
  }, 
    delete: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
      _termService.delete(id)
          .then(
              response => {
                  dispatch({ type: "DELETE_TERMOFUSE",});
                  _termService.get()
                      .then(
                          response => {
                              dispatch({ type: "RECIEVED_TERMOFUSE", termOfUses: response });
                          },
                          error => {
                              dispatch({ type: "RECIEVED_FAILURE", error });
                          }
                      )
              },
              error => {
                  dispatch({ type: "RECIEVED_FAILURE", error });
              }
          );
    } 
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const initialState: TermOfUseState = { isLoading: false, termOfUses: []};
export const reducer: Reducer<TermOfUseState> = (state: TermOfUseState, incomingAction: Action) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
      case "REQUEST_TERMOFUSE":
          return { isLoading: true, termOfUses:  state.termOfUses};
      case "RECIEVED_TERMOFUSE":
          return { isLoading: false, termOfUses: action.termOfUses};
      case "RECIEVED_FAILURE":
          return { isLoading: false, error: action.error, termOfUses: state.termOfUses};
      case "ADD_TERMOFUSE":
          return {};
      case "DELETE_TERMOFUSE":
          return {};
      case "EDIT_TERMOFUSE":
          return {};
      default:
          return state || initialState
  }
};