import { Reducer, Action } from "redux";
import { userService } from "../services/user.service";
import { AppThunkAction } from ".";
import { history } from '../helpers/history';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthState {
    loggingIn?: boolean;
    user?: User;  
    error?: any;
}

export interface User {
    token: string;
    tokenExpirationTime: string;
    id: number;    
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
interface LoginRequestAction { type: 'USERS_LOGIN_REQUEST', user: User }
interface LoginSuccesAction { type: 'USERS_LOGIN_SUCCESS', user: User }
interface LoginFailureAction { type: 'USERS_LOGIN_FAILURE', error: any }
interface LogoutAction { type: 'USERS_LOGOUT' }
interface RegisterRequestAction { type: 'USERS_REGISTER_REQUEST', user: any }
interface RegisterSuccessAction { type: 'USERS_REGISTER_SUCCESS', user: any }
interface RegisterFailureAction { type: 'USERS_REGISTER_FAILURE', user: any }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = LoginSuccesAction | LoginRequestAction | LoginFailureAction | LogoutAction
    | RegisterRequestAction | RegisterSuccessAction | RegisterFailureAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    login: (username, password, props): AppThunkAction<KnownAction> => (dispatch, getState) => {        
        dispatch({ type: "USERS_LOGIN_REQUEST", user });
        userService.login(username, password)
            .then(
                user => {
                    dispatch({ type: "USERS_LOGIN_SUCCESS", user });
                    props.history.push('/');
                },
                error => {
                    dispatch({ type: "USERS_LOGIN_FAILURE", error });                        
                }
        );                     
    },
    logout: (props): AppThunkAction<KnownAction> => (dispatch, getState) => {
        userService.logout();
        dispatch({ type: "USERS_LOGOUT" });           
        props.history.push('/login');
    },
    register: (user): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "USERS_REGISTER_REQUEST", user });
        userService.register(user)
            .then(
                user => {
                    dispatch({ type: "USERS_REGISTER_SUCCESS", user });
                    history.push('/login');
                },
                error => {
                    dispatch({ type: "USERS_REGISTER_FAILURE", user });
                }
            );
    }
};

let user = JSON.parse(localStorage.getItem('user') || '{}') as User;
const initialState: AuthState = user ? { loggingIn: false, user } : {} as AuthState;

export const reducer: Reducer<AuthState> = (state: AuthState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    //console.log(state);
    switch (action.type) {
        case "USERS_LOGIN_REQUEST":
            return {
                loggingIn: true,
                user: action.user
            };
        case "USERS_LOGIN_SUCCESS":
            return {
                loggingIn: true,
                user: action.user
            };
        case "USERS_LOGIN_FAILURE":
            return {
                loggingIn: false,
                error: action.error
            };     
        case "USERS_LOGOUT":
            return {
                loggingIn: false                
            };     
        case "USERS_REGISTER_REQUEST":
            return {
                
            };
        case "USERS_REGISTER_SUCCESS":
            return {
                
            };
        case "USERS_REGISTER_FAILURE":
            return {
                
            };     
        default:
            const exhaustiveCheck: never = action;            
    }
    return state || initialState;
}