import { AppThunkAction } from ".";
import { Action, Reducer } from "redux";
import { baseService } from "../services/base.service";
import {countryInfoService} from "../services/countryInfo.service";
import {CountryInfoModel} from "../components/CountryInfo";


// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface CountryInfoState {
    isLoading?: boolean,
    countryInfos?: any[],
    error?: any
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface RequestCountryInfoAction { type: "REQUEST_COUNTRYINFO" }
interface RecieveCountryInfoAction { type: "RECIEVED_COUNTRYINFO", countryInfos: any[] }
interface FailureCountryInfoAction { type: "RECIEVED_FAILURE", error?: any }
interface EditCountryInfoAction {type: 'EDIT_COUNTRYINFO'}
interface AddCountryInfoAction {type: 'ADD_COUNTRYINFO'}
interface DeleteCountryInfoAction {type: 'DELETE_COUNTRYINFO'}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestCountryInfoAction | RecieveCountryInfoAction | FailureCountryInfoAction | EditCountryInfoAction | AddCountryInfoAction | DeleteCountryInfoAction

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    getCountryInfos: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_COUNTRYINFO" });
        countryInfoService.getCountryInfo()
            .then(
                response => {
                    dispatch({ type: "RECIEVED_COUNTRYINFO", countryInfos: response });
                },
                error => {
                    dispatch({ type: "RECIEVED_FAILURE", error });
                }
            );
    },
    edit: (model: CountryInfoModel) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        countryInfoService.editCountryInfo(model)
            .then(
                response => {
                    dispatch({ type: "EDIT_COUNTRYINFO"});
                    countryInfoService.getCountryInfo()
                        .then(
                            response => {
                                dispatch({ type: "RECIEVED_COUNTRYINFO", countryInfos: response });
                            },
                            error => {
                                dispatch({ type: "RECIEVED_FAILURE", error });
                            }
                        );
                },
                error => {
                    dispatch({ type: "RECIEVED_FAILURE", error });
                }
            );
        
    },
    add: (model: CountryInfoModel) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        countryInfoService.addCountryInfo(model)
            .then(
                response => {
                    dispatch({ type: "ADD_COUNTRYINFO",});
                    
                    countryInfoService.getCountryInfo()
                        .then(
                            response => {
                                dispatch({ type: "RECIEVED_COUNTRYINFO", countryInfos: response });
                            },
                            error => {
                                dispatch({ type: "RECIEVED_FAILURE", error });
                            }
                        );
                },
                error => {
                    dispatch({ type: "RECIEVED_FAILURE", error });
                }
            );

    },
    delete: (id: number) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        countryInfoService.deleteCountryInfo(id)
            .then(
                response => {
                    dispatch({ type: "DELETE_COUNTRYINFO",});
                    countryInfoService.getCountryInfo()
                        .then(
                            response => {
                                dispatch({ type: "RECIEVED_COUNTRYINFO", countryInfos: response });
                            },
                            error => {
                                dispatch({ type: "RECIEVED_FAILURE", error });
                            }
                        );
                },
                error => {
                    dispatch({ type: "RECIEVED_FAILURE", error });
                }
            );

    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const initialState: CountryInfoState = { isLoading: false, countryInfos: [] };
export const reducer: Reducer<CountryInfoState> = (state: CountryInfoState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_COUNTRYINFO":
            return { isLoading: true, countryInfos: [] };
        case "RECIEVED_COUNTRYINFO":
            return { isLoading: false, countryInfos: action.countryInfos };
        case "RECIEVED_FAILURE":
            return { isLoading: false, countryInfos: [], error: state.error };
        case "EDIT_COUNTRYINFO":
            return { };
        case "ADD_COUNTRYINFO":
            return { };
        case "DELETE_COUNTRYINFO":
            return { };    
        default:
            return state || initialState
    }
}