import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Counter from './components/Counter';
import FetchData from './components/FetchData';
import Login from './components/Login';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import MedicalTerm from './components/MedicalTerm';
import CountryInfo from './components/CountryInfo';
import TermOfUse from "./components/TermOfUse/TermOfUse";

export default () => (
  <div>
      <Route exact path='/login' component={Login} />      
      <PrivateRoute exact path='/' component={MedicalTerm} />
      <PrivateRoute exact path='/counter' component={Counter} />
      <PrivateRoute path='/fetchdata/:startDateIndex?' component={FetchData} />
      <PrivateRoute path='/medterms' component={MedicalTerm} />
      <PrivateRoute path='/country-info' component={CountryInfo} />              
      <PrivateRoute path='/term-of-use' component={TermOfUse} />              
  </div>
);
