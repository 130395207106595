import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './NavMenu.css';
import * as Auth from '../store/authReducer';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

type NavMenuProps =
    Auth.AuthState
    & typeof Auth.actionCreators;   

class NavMenu extends React.Component<NavMenuProps, {}> {
    constructor(props) {
        super(props);
    }

    handleClickExit = (e) => {
        this.props.logout(this.props);        
    }

    public render() {
        return <div className='main-nav'>
            <div className='navbar navbar-inverse'>
                <div className='navbar-header'>
                    <button type='button' className='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse'>
                        <span className='sr-only'>Toggle navigation</span>
                        <span className='icon-bar'></span>
                        <span className='icon-bar'></span>
                        <span className='icon-bar'></span>
                    </button>
                    <Link className='navbar-brand' to={'/'}>Панель администратора TravelApp</Link>
                    <button className="btn btn-primary exit" onClick={this.handleClickExit}>Выйти</button>
                </div>
                <div className='clearfix'></div>
                <div className='navbar-collapse collapse'>
                    <ul className='nav navbar-nav'>
                        {/*<li>*/}
                        {/*    <NavLink exact to={'/'} activeClassName='active'>*/}
                        {/*        <span className='glyphicon glyphicon-home'></span> Главная*/}
                        {/*    </NavLink>*/}
                        {/*</li>                        */}
                        <li>
                            <NavLink to={'/medterms'} activeClassName='active'>
                                <span className='glyphicon glyphicon-list-alt'></span> Медицинские термины
                            </NavLink>
                        </li>
                        {/*<li>
                            <NavLink to={'/counter'} activeClassName='active'>
                                <span className='glyphicon glyphicon-tint'></span> Медикоменты
                            </NavLink>
                        </li>*/}
                        {/*<li>
                            <NavLink to={'/fetchdata'} activeClassName='active'>
                                <span className='glyphicon glyphicon-th-list'></span> Fetch data
                            </NavLink>
                        </li>*/}
                        <li>
                            <NavLink to={'/country-info'} activeClassName='active'>
                                <span className='glyphicon glyphicon-plane'></span> Информация о странах
                            </NavLink>
                        </li>     
                        <li>
                            <NavLink to={'/term-of-use'} activeClassName='active'>
                                <span className='glyphicon glyphicon-plane'></span> Правила использования
                            </NavLink>
                        </li>                     
                    </ul>
                </div>
            </div>
        </div>;
    }
}

export default connect(
    (state: ApplicationState) => state.authentication,
    Auth.actionCreators
)(NavMenu);
