import React, { DetailedReactHTMLElement } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import NavMenu from './NavMenu';

export class Layout extends React.Component {
    public render() {        
        
        if (localStorage.getItem('user')) {
            return (
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <NavMenu {...this.props}/>
                        </div>
                        <div className='col-sm-9'>
                            {this.props.children}
                        </div>
                    </div>
                </div>)
        }
        return <div className='container-fluid'>
            <div className='row'>                
                <div className='col-sm-12'>
                    {this.props.children}
                </div>
            </div>
        </div>;
    }
}
