import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import * as Authentication from './authReducer';
import * as MedTerm from './MedicalTerm';
import * as CountryInfo from './CountryInfo';
import * as TermOfUse from './TermOfUseReducer/TermOfUseReducer';

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState;
    weatherForecasts: WeatherForecasts.WeatherForecastsState;
    authentication: Authentication.AuthState;
    medTerm: MedTerm.MedTermState;
    countryInfo: CountryInfo.CountryInfoState,
    termOfUse: TermOfUse.TermOfUseState
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    authentication: Authentication.reducer,
    medTerm: MedTerm.reducer,
    countryInfo: CountryInfo.reducer,
    termOfUse: TermOfUse.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
