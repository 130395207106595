import {BaseCrudService} from "../BaseCrudService";
import {TermOfUseModel} from "../../store/TermOfUseReducer/TermOfUseReducer";
import {baseService} from "../base.service";

export class TermOfUseService extends  BaseCrudService<TermOfUseModel> {
    constructor(url: string) {
        super(url);
    }

    get(){
        return baseService.httpGet(this._url + '/Get');
    }
}