import { baseService } from "./base.service";
import {CountryInfoModel} from "../components/CountryInfo";

export const countryInfoService = {
    editCountryInfo,
    getCountryInfo,
    addCountryInfo,
    deleteCountryInfo
};

function editCountryInfo(model: CountryInfoModel) {
    return baseService.httpPost(`api/CountryInfo/Edit`, model);
}

function getCountryInfo() {
    return baseService.httpGet(`api/CountryInfo`).then(
        resp => {
            let data = resp.map(r => {
                let [ru, en] = JSON.parse(r.wikipediaLink);
                r.wikiRu = ru.link;
                r.wikiEn = en.link;

                return r;
            });
            return data;
        }
    );
}

function addCountryInfo(model: CountryInfoModel) {
    return baseService.httpPost(`api/CountryInfo/Add`, model);
}

function deleteCountryInfo(id: number) {
    return baseService.httpPost(`api/CountryInfo/Delete?id=${id}`);
} 
