import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { fontWeight } from '@material-ui/system';
import { TablePagination } from '@material-ui/core';
import { array } from 'prop-types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
            overflowX: 'auto',            
        },
        table: {
            minWidth: 650,
        },
        headerCells: {
            fontSize: '15px',
            fontWeight: "bold",
            color: "black"
        },
        bodyCells: {
            fontSize: '15px'
        },
        pagination: {
            fontSize: '10px'
        }
    }),
);

interface Props {
    headers: any[];
    data: any[][];
}

export default function SimpleTable(props: Props) {
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const data = props.data;
    let headers = props.headers;  

    function handleChangePage(event: unknown, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    function renderRow(allData: any[][]) {
        let rows: any[] = [];

        let count = 0;
        allData.forEach(row => {
            let data: any[] = [];

            row.forEach((elem, index) => {
                data.push(
                    <TableCell className={classes.bodyCells} key={index}>
                        {elem.value}
                    </TableCell>
                );
            });
            
            rows.push(
                <TableRow key={count++}>
                    {data}
                </TableRow>
            );
        });

        return rows;
    }

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {headers.map((h, index) => <TableCell className={classes.headerCells} key={index}>{h}</TableCell>)}                       
                    </TableRow>
                </TableHead>
                <TableBody>                    
                    {renderRow(data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))}
                </TableBody>
            </Table>
            <TablePagination
                classes={
                    {
                        root: classes.pagination,
                        input: classes.pagination,
                        caption: classes.pagination
                    }
                }
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
