import { authHeader } from "../helpers/auth-header";
import { userService } from "./user.service";
import { history } from '../helpers/history';

export const baseService = {
    httpGet,
    handleResponse,
    httpPost
}

function httpGet(url) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    requestOptions.headers = {
        ...requestOptions.headers, ...authHeader() as {}
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function httpPost(url, body?) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    };
    requestOptions.headers = {
        ...requestOptions.headers, ...authHeader() as {}
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function handleResponse(response) {
return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api                
            userService.logout();
            history.push('/login');
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    return data;
    });
}
