import {baseService} from "./base.service";

export class BaseCrudService<T> {
    _url: string;
    constructor(url: string) {
        this._url = url;
    }
    
    get(){
        return baseService.httpGet(this._url);
    }
    
    edit(object: T){
        return baseService.httpPost(this._url + '/Edit', object);
    }
    
    add(object: T){
        return baseService.httpPost(this._url + '/Add', object);
    }
    
    delete(id: number){
        return baseService.httpPost(this._url + `/Delete?id=${id}`);
    }
}