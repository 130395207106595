import { AppThunkAction } from ".";
import { medtermService } from "../services/medterm.servie";
import { Reducer, Action } from "redux";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface MedTermState {
    isLoading?: boolean,
    medicalTerms: any[],
    error?: any
}

export interface MedTerm {
    name: string,
    DE: string,
    EL: string,      
    EN: string,
    ES: string,
    FR: string, 
    HE: string, 
    HR: string, 
    IT: string, 
    JA: string,
    KO: string,
    NL: string,
    PL: string, 
    PT: string,
    RU: string,
    TH: string,
    UK: string,
    ZH: string,
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface RequestMedTermAction { type: "REQUEST_MEDTERMS" }
interface RecieveMedTerms { type: "RECIEVED_MEDTERMS", medicalTerms: any[] }
interface FailureMedTerms { type: "RECIEVED_FAILURE", error?: any }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestMedTermAction | RecieveMedTerms | FailureMedTerms

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    getTerms: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_MEDTERMS" });
        medtermService.getMedterms()
            .then(
                response => {
                    dispatch({ type: "RECIEVED_MEDTERMS", medicalTerms: response });
                },
                error => {
                    dispatch({ type: "RECIEVED_FAILURE", error });
                }
            );
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const initialState: MedTermState = { isLoading: false, medicalTerms: [] } 
export const reducer: Reducer<MedTermState> = (state: MedTermState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_MEDTERMS":
            return { isLoading: true, medicalTerms: [] }
        case "RECIEVED_MEDTERMS":
            return { isLoading: false, medicalTerms: action.medicalTerms }
        case "RECIEVED_FAILURE":
            return { isLoading: false, medicalTerms: [], error: state.error }
        default:
            return state || initialState
    }
}