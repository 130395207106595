import React from 'react';
import MaterialTable, {Column, MTablePagination} from 'material-table';

interface Row {
    name: string;
    surname: string;
    birthYear: number;
    birthCity: number;
}

interface TableState {
    columns: Array<Column<any>>;
    data: any[];
}

interface TableProps {
    headers: Header[];
    data: any[] | undefined;
    onEdit: (object: any) => void;
    onAdd: (object: any) => void;
    onDelete: (id: number) => void;
}

interface Header {
    value: string,
    field: string
    cellStyle?: any
}



export default function EditableTable(props: TableProps) {
    let cols = props.headers.map(h => {
        return {title: h.value, field: h.field, cellStyle: h.cellStyle};
    });
    let data = props.data ? props.data : [] as any;
    
    let formatNewVal = (val) => {
        let res = [
            {
                lang: "ru",
                link: `${val.wikiRu}`
            },  {
                lang: "en",
                link: `${val.wikiEn}`
            }
        ];
        val.wikipediaLink = JSON.stringify(res);
        delete val.wikiEn;
        delete val.wikiRu;
        
        return val;
    };
    
    return (
        <MaterialTable
            options={ {
                headerStyle: {fontSize: '15px', color: "black"},
                actionsCellStyle: {fontSize: '15px'},
                filterCellStyle: {fontSize: '15px'},
                rowStyle: {fontSize: '15px'},
                pageSize: 10
            } }
            title=""
            columns={cols}
            data={data}
            editable={{
                onRowAdd: newData =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            if (newData){
                                // let res = [
                                //     {
                                //     lang: "ru",
                                //     link: `${newData.wikiRu}`
                                // },  {
                                //     lang: "en",
                                //     link: `${newData.wikiEn}`
                                // }
                                // ];
                                // newData.wikipediaLink = JSON.stringify(res);
                                // delete newData.wikiEn;
                                // delete newData.wikiRu;

                                newData = formatNewVal(newData);
                                
                                props.onAdd(newData);
                            }                             
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            if (oldData != newData){
                                newData = formatNewVal(newData);
                                
                                props.onEdit(newData);
                            }
                        }, 600);
                    }),
                onRowDelete: oldData =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            if (oldData !== null){
                                props.onDelete(oldData.id as number);
                            }                             
                        }, 600);
                    }),
            }}
        />
    );
}