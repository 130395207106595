import { userService } from "./user.service";
import { baseService } from "./base.service";

export const medtermService = {
    getMedterms
}

function getMedterms() {    
    return baseService.httpGet(`api/MedicalTerm/GetFullName`);
}
