import React from 'react';
import {ApplicationState} from '../store';
import {connect} from 'react-redux';
import * as CountryInfoState from '../store/CountryInfo'
import EditableTable from "./Table/EditableTable";


type CountryInfoProps =
    CountryInfoState.CountryInfoState
    & typeof CountryInfoState.actionCreators

export interface CountryInfoModel {
    id: number,
    code: string,
    ambulancePhoneNumber: string,
    fireDepartmentPhoneNumber: string,
    policePhoneNumber: string,
    wikipediaLink: string
}
class CountryInfo extends React.Component<CountryInfoProps> {
    constructor(props) {
        super(props);
    }

    headers = [{value: "Страна", field: "name", cellStyle: {fontSize: '15px'}}, 
        {value: "Код", field: "code", cellStyle: {fontSize: '15px'}}, 
        {value: "Номер телефона спасения", field: "ambulancePhoneNumber", cellStyle: {fontSize: '15px'} }, 
        {value: "Номер пожарной службы", field: "fireDepartmentPhoneNumber", cellStyle: {fontSize: '15px'}}, 
        {value: "Номер полиции", field: "policePhoneNumber", cellStyle: {fontSize: '15px'}}, 
        {value: "Ссылка на википедию (RU)", field: "wikiRu", cellStyle: {fontSize: '15px', maxWidth: '50px', overflowWrap: 'break-word'}},
        {value: "Ссылка на википедию (EN)", field: "wikiEn", cellStyle: {fontSize: '15px', maxWidth: '50px', overflowWrap: 'break-word'}}];

    componentDidMount() {
        this.props.getCountryInfos();
    }
    
    onEdit = (info: CountryInfoModel) => {
        this.props.edit(info);
    };
    
    onAdd = (info: CountryInfoModel) => {
        this.props.add(info);
    };
    
    odDelete = (id: number) => {
        this.props.delete(id);
    };

    render() {
        return <div>
            <h1>Информация о странах</h1>
            {this.props.countryInfos && this.props.countryInfos.length > 0 &&
            <EditableTable
                onEdit={this.onEdit}
                onDelete={this.odDelete}
                onAdd={this.onAdd}
                headers={this.headers}
                data={this.props.countryInfos}
            />}
        </div>;
    }
}

export default connect(
    (state: ApplicationState) => state.countryInfo, // Selects which state properties are merged into the component's props
    CountryInfoState.actionCreators                 // Selects which action creators are merged into the component's props
)(CountryInfo) as typeof CountryInfo;