import React from 'react';
import * as MedTermState from '../store/MedicalTerm'
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import SimpleTable from './Table/Table';


type MedicalTermProps =
    MedTermState.MedTermState
    & typeof MedTermState.actionCreators;


class MedicalTerm extends React.Component<MedicalTermProps, {}> {
    componentDidMount() {
        this.props.getTerms();
    }

    public render() {        
        let headers: string[] = [];
        let data: any[][] = [];
        if (this.props.medicalTerms.length > 0) {
            headers = this.props.medicalTerms[0].map(t => t.fullLanguage);
            data = this.props.medicalTerms;
        }
            
        return <div>
            <h1>Медицинские термины</h1>
            <SimpleTable headers={headers} data={this.props.medicalTerms} />
        </div>;
    }            
}

export default connect(
    (state: ApplicationState) => state.medTerm, // Selects which state properties are merged into the component's props
    MedTermState.actionCreators                 // Selects which action creators are merged into the component's props
)(MedicalTerm) as typeof MedicalTerm;
